import React from "react";

const Competitions = [
  {
    title:
      "Gresham Barlow Science Expo in Computer Science, Competitor, (9th-11th)",
    description:
      "Won 3rd place with my presentation on for forest fire research.",
    details:
      "Won 1st place overall for pancreatic cancer project Northwest Science Expo in Medicine",
  },
  {
    title: "Northwest Science Expo, Competitor, (9th-11th)",
    description:
      "Won Pacific Northwest Scientist Award for forest fire research.",
    details: "Won 2nd place overall for pancreatic cancer project",
  },
  {
    title: "HOSA, Competitor",
    description:
      "Won 1st place for my Research Poster on pancreatic cancer research Qualified for International Leadership Conference in Dallas, Texas",
    details: "",
  },

  {
    title: "Cyber patriot",
    description:
      "Formed Team Oregon with 5 friends Hacked in the nationwide competition cyberpatriot, a competition designed for high school students to demonstrate their skills in computer hacking",
    details: "Won 2nd in the West Coast.",
  },
];

const researches = [
  {
    title: "CNN-LSTM- SCSO: An hybrid approach for Forest Fire Prediction",
    description: "",
    details:
      "Project Abstract: The forest fire disaster brings forth significant consequences, encompassing threats to both the flora and fauna environment, along with the overarching challenge of global warming. Integrating various Machine Learning methodologies stands out as a crucial approach for monitoring the propagation of fires within forested regions. By doing so, it becomes possible to avert hazardous situations, curbing the potential for environmental and property losses. Consequently, the ability to accurately identify the progression of fire, particularly its impact on burned areas, has gained paramount importance in mitigating the catastrophic aftermath of forest fires. Current research endeavors have been directed towards enhancing the precision of monitoring systems. In this pursuit, a novel approach was adopted: the fusion of Convolutional Neural Networks (CNN), Long Short-Term Memory networks (LSTM), and Sand-cat Swarm Optimization (SCSO). This hybrid methodology, denoted as CNN-LSTM-SCSO, was harnessed for predicting forest fires. Notably, this approach exhibited superior efficacy when compared to conventional methods, marking a significant stride towards proactive fire management and prevention",
    buttons: [
      {
        label: "Research Paper",
        link: "https://docs.google.com/presentation/d/1B2LLBanaqm48xIw5kZ19AEd7wQRjTIO0/edit?usp=sharing&ouid=115249817032565692532&rtpof=true&sd=true",
        type: "primary",
      },
      {
        label: "Documentation",
        link: "https://docs.google.com/document/d/1_Wu14vtxQEeljMa4tZ_GQw1RlttbUiM7/edit?usp=sharing&ouid=115249817032565692532&rtpof=true&sd=true",
        type: "secondary",
      },
      {
        label: "GitHub",
        link: "https://github.com/anviverma-code/cnnforforestfire",
        type: "primary",
      },
    ],
  },
  {
    title: "PanCan Stage Identifier",
    description:
      "A Transfer Learning Based Multi- Tumor Classification Framework Approach For Pancreatic Tumor Stage and Substage Classification",
    details:
      "Project Abstract:Pancreatic cancer, a leading cause of over 50,000 deaths annually, suffers from poor prognosis mainly due to late detection. Existing diagnostic hurdles, such as difficulty in distinguishing tumors and size variability, call for improved detection methods. This research pioneers the Pan Can Stage Tracker tool, focusing on sub-staging, providing detailed insights into disease progression. Unlike traditional methods hindered by the pancreas's deep location, sub- staging offers precise assessments, aiding in accurate prognostic predictions and treatment decisions. The methodology involves machine learning, leveraging preprocessing of abdominal CT images, transfer learning for tumor classification, and pancreas segmentation through LSTUN segmentation. Feature extraction includes radiomic features, higher-order statistics, and GLCM statistics, with optimal feature selection and data augmentation techniques applied. Importantly, this approach shows promise for repurposing in other cancer types for preprocessing and tumor differentiation. Additionally, the utilization of KCPC in abdominal region mapping enables identification of both stages and substages with an impressive accuracy of over 98%, potentially escalating the 5-year survival rate from 3% to 44% through early Detection.",
    buttons: [
      {
        label: "Research Paper",
        link: "https://docs.google.com/document/d/1TSbZBCAJM1eDdQDhJ5oDKI64-5zJ1xVI/edit?usp=sharing&ouid=115249817032565692532&rtpof=true&sd=true",
        type: "primary",
      },
      {
        label: "Documentation",
        link: "https://docs.google.com/presentation/d/1nLhTaFOoAQfJaKHuOUZdMQk8mNmx-cRl/edit?usp=sharing&ouid=115249817032565692532&rtpof=true&sd=true",
        type: "secondary",
      },
      {
        label: "GitHub",
        link: "https://github.com/anviverma-code/pancanstage",
        type: "primary",
      },
    ],
  },

  {
    title: "Shelter Connect Mobile Application",
    description:
      "Developed a fully functioning application that allows local users to view nearby shelters and what products they accept or need; used Javascript and x code to create the app.",

    details:
      "Included features like a resource locator, AI integrated chat bot, and various health and crisis lines to help the homelessness society.",
    buttons: [
      {
        label: "GitHub",
        link: "https://github.com/anviverma-code/shelterconnect",
        type: "primary",
      },
      {
        label: "YouTube",
        link: "https://youtu.be/dimlCS554RI",
        type: "secondary",
      },
    ],
  },

  {
    title: "PedAppl Mobile Application",
    description:
      "Developed a fully functioning application that allows users to report local street faults; today, many faulty streets, sidewalks, broken traffic/pedestrian crossing lights that should be reported are now made a lot easier using PedAppl as requests can be sent to the local mayor directly.",
    details: "Programmed this app using Swift and Xcode Apple App Developer.",

    buttons: [
      {
        label: "Code",
        link: "https://drive.google.com/drive/folders/1UryJvehKKjoA08MsDSZ2tB2j2X0l2NxQ",
        type: "primary",
      },
      {
        label: "Demo",
        link: "https://drive.google.com/file/d/10z_x8WQgCQz7lhfCA8Phx02dgoLDRMA-/view?usp=sharing",
        type: "primary",
      },
    ],
  },
];

const communityServices = [
  {
    title: "Royal Family Kids Camp, Volunteer, 65+ hours, (10th-11th)",
    description: "",
    details:
      "Supported young children by volunteering as a full time camp counsler and assisting children in various outdoor activities such as rowing, swimming, ziplining, arts and crafts, etc… Through this organization for children in foster care",
  },
  {
    title: "Cedar Sinai Park, Volunteer, 8+ hours, (9th-11th)",
    description: "",
    details:
      "Worked in this assisted living facility and supported residents by helping the residents move and managing games such as beach ball and bingo",
  },

  {
    title: "Blanchet House, Volunteer, 10 hours, (9th-10th)",
    description: "",
    details:
      "Served food in downtown Portland for the homeless Oregon Food Bank",
  },

  {
    title: "JET USA",
    description:
      "Worked as a tutor under JET Oregon, a spiritual program located in Portland",
    details:
      "Worked for 4 months, 3 hours/week for 5+ students.Spiritually involved in local community service events such as collecting recycling and compost/ creating food for homeless",
  },
];

const stemBasedVolunteering = [
  {
    title:
      "Oregon Museum of Science and Technology (OMSI) Teen Science Alliance, Volunteer / Leader, (9th-11th) (170+ hours)",
    description: "",
    details:
      "Created educational videos on topics including photosynthesis for kids to learn at OMSI Demonstrated leadership and commitment not only worked in the physics lab but also taking on managerial responsibilities.",
  },
  {
    title: "Bit by Bit Coding, Marketing Volunteer, (9th-11th)",
    description: "",
    details:
      "Created social media posts weekly/biweekly for a company dedicated to helping students learn more about coding languages Helped renovate/launch Bit by Bit’s newest project, Form and Function, which is a branch of the company that teaches middle schoolers introductory coding/tech",
  },

  {
    title: "STEM Like a Girl Workshop Volunteer, 9th-10th",
    description: "",
    details:
      "Assisted at monthly STEM workshops as the design challenge mentor Workshops consisted of teaching 1 hour javascript game creation through code.org",
  },

  {
    title: "PSU Center for Entrepreneurship, Workshop Volunteer, (9th-11th)",
    description: "",
    details:
      "Worked for 4 months, 3 hours/week for 5+ students.Spiritually involved in local community service events such as collecting recycling and compost/ creating food for homeless",
  },

  {
    title: "Women in Science Blog, Contributor, (9th-11th)",
    description: "",
    details:
      "Dedicated my blog posts to amplifying the voices of women in science. Conducted interviews with accomplished female scientists and engaged in insightful discussions to write engaging narratives about their experiences. Leveraged my writing to shed light on the experiences, achievements, and contributions of these women to their respective fields, inspiring readers to pursue careers in STEM. ",
  },

  {
    title: "TechGirlz Workshop, Volunteer, (9th-11th)",
    description: "",
    details:
      "Assisted with the instruction of 2 web design workshops and 1 “Intro to Coding” workshop through the TechGirlz organization 45 minute workshops",
  },

  {
    title: "UPchieve, Tutor, (9th-11th)",
    description: "",
    details:
      "Tutored students grades 5-8 in STEM subjects including biology, all levels of math until calculus Tutored 4 Students weekly for 1 hour sessions.",
  },
];

const internships = [
  {
    company: "Ziply Fibers",
    role: "Computer Science Intern",
    duration:
      "Worked 4 hours part-time for two summers and 2 hours weekly during the school year",
    responsibilities: [
      {
        title: "Data Analysis",
        description:
          "Performed simple data analysis tasks using tools such as Excel, Python, and R. This involved cleaning, organizing, and analyzing data sets to extract meaningful insights and trends.",
      },
      {
        title: "Coding Assistance",
        description:
          "Assisted with writing, debugging, and testing code under the supervision of more experienced developers. Gained hands-on experience in programming languages and development practices.",
      },
      {
        title: "Website Maintenance",
        description:
          "Helped to update and maintain websites, including content updates and basic front-end modifications. This included working with HTML, CSS, and JavaScript to ensure websites were up-to-date and user-friendly.",
      },
    ],
  },
  {
    company: "International Institute of Information Technology",
    role: "Imaging and Segmentation Intern",
    duration: "Part-time, 5 hours/day during the summer",
    responsibilities: [
      {
        title: "Algorithm Debugging",
        description:
          "Worked in the imaging and segmentation branch, helping to debug segmentation algorithms. This involved identifying and fixing issues in the code to improve algorithm performance.",
      },
      {
        title: "Dataset Testing",
        description:
          "Tested segmentation algorithms on various new datasets to evaluate their accuracy and efficiency. Provided feedback and suggestions for further improvements to the algorithms based on test results.",
      },
    ],
  },
];

const Card = ({ project, index }) => {
  return (
    <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
      {project?.image?.length >= 1 && (
        <img
          src={project.image}
          alt={project.title}
          className="w-full h-48 object-cover"
        />
      )}
      <div className="p-6 leading-[46px]">
        <h3 className="text-2xl font-bold text-gray-800 leading-[46px]">
          {project.title}
        </h3>
        <p className="text-blue-700 mt-2 leading-[46px]">
          {project.description}
        </p>
        <p className="text-gray-700 mt-4 leading-[46px]">{project.details}</p>
        <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-x-2 gap-y-2">
          {project?.buttons?.map((button, btnIndex) => (
            <a
              sty
              key={btnIndex}
              href={button.link}
              className={`text-center px-4  rounded-lg text-white ${
                button.type === "primary" ? "bg-black" : "bg-red-600"
              } hover:opacity-80 transition-opacity`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {button.label}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

const InternshipCard = ({ company, role, duration, responsibilities }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden leading-[46px]">
      <div className="p-6">
        <h1 className="text-2xl font-bold text-gray-800 leading-[46px]">
          {company}, Intern
        </h1>
        <h2 className="text-blue-700 mt-2 leading-[46px]">{role}</h2>
        <p className="text-md text-black mb-2 leading-[46px]">{duration}</p>
        <h3 className="text-md font-semibold text-gray-700">
          Responsibilities:
        </h3>
        <ul className="list-disc list-inside">
          {responsibilities.map((responsibility, index) => (
            <li key={index} className="text-black mt-2">
              <strong>{responsibility.title}:</strong>{" "}
              {responsibility.description}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Activity = () => {
  return (
    <div className="bg-gray-100 py-7">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-8">
          Internships
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          {internships.map((internship, index) => (
            <InternshipCard
              key={index}
              company={internship.company}
              role={internship.role}
              duration={internship.duration}
              responsibilities={internship.responsibilities}
            />
          ))}
        </div>
      </div>

      <div className="max-w-6xl mx-auto px-4 mt-8 mb-4">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-8">
          Competitions
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          {Competitions.map((project, index) => (
            <Card key={index} project={project} />
          ))}
        </div>
      </div>

      <div className="max-w-6xl mx-auto px-4 mt-8">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-8">
          Research
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          {researches.map((project, index) => (
            <Card key={index} project={project} />
          ))}
        </div>
      </div>

      <div className="max-w-6xl mx-auto px-4 mt-8">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-8">
          Community Services
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          {communityServices.map((project, index) => (
            <Card key={index} project={project} />
          ))}
        </div>
      </div>

      <div className="max-w-6xl mx-auto px-4 mt-8">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-8">
          Stem Based Volunteering
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          {stemBasedVolunteering.map((project, index) => (
            <Card key={index} project={project} />
          ))}
        </div>
      </div>

      <div className="max-w-6xl mx-auto px-4 mt-8">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-3">
          Founder of Local Non Profit Prime Promotion
        </h2>
        <div className="bg-gray-100 py-2 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <p className=" text-gray-600 leading-[46px]">
              I collaborated with three friends to launch an organization
              dedicated to supporting small businesses in expanding their
              customer base. Leveraging our combined expertise in social media
              applications and various marketing platforms, we have successfully
              assisted over 20 small businesses in reaching wider audiences. Our
              efforts include improving advertising strategies through targeted
              social media campaigns, creating engaging and relevant content,
              and optimizing overall marketing tactics. By building customized
              social media pages, revamping websites, and crafting compelling
              promotional materials, we have significantly enhanced the online
              presence and customer engagement for these businesses.
              Additionally, we provide ongoing support and analytics to ensure
              sustained growth and adaptability in competitive markets, helping
              these small businesses thrive and achieve their goals.
            </p>

            <div className="mt-6 ml-10 space-y-8 text-gray-600">
              <p className="my-3">
                ▪ Created over 20 social media pages (including customized
                posts) and 6 websites.
              </p>
              <p className="my-3">▪ Sample Organizations that were serviced:</p>
              <p className="my-3">
                ▪ New Day Real Estate Solutions (website creation + Instagram
                page)
              </p>
              <p className="my-3">
                ▪ Unger's Farm Store (Facebook creation, website revamp)
              </p>
              <p className="my-3">▪ Evans Farm (commercial promotion)</p>
              <p className="my-3">▪ La Porteña (Instagram page)</p>
              <p className="my-3">
                ▪ Deck Family Farm (Facebook, Instagram, website revamp)
              </p>
              <p className="my-3">▪ Forest Candy (Instagram + WhatsApp)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
