/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const NavBar = ({
  scrollToAbout,
  scrollToTop,
  scrollToActivity,
  scrollToContact,
}) => {
  return (
    <nav
      className="bg-white pt-3"
      style={{
        fontFamily: "Pangram-Regular",
      }}
    >
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-center">
          <div className="flex flex-wrap items-center lg:gap-12 md:gap-9 gap-8 justify-center lg:px-2 md:px-2 p-4">
            <a
              onClick={scrollToTop}
              className="text-black text-md font-bold hover:text-gray-900 cursor-pointer"
            >
              Home
            </a>
            <a
              onClick={scrollToAbout}
              className="text-black text-md font-bold hover:text-gray-900 cursor-pointer"
            >
              About
            </a>

            <a
              onClick={scrollToActivity}
              className="text-black text-md font-bold hover:text-gray-900 cursor-pointer"
            >
              Internships
            </a>

            <a
              onClick={scrollToContact}
              className="text-black text-md font-bold hover:text-gray-900 cursor-pointer"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
