import React from "react";
import MainScreen from "./Screens/MainScreen";

const App = () => {
  return (
    <div>
      <MainScreen />
    </div>
  );
};

export default App;

